// hard-coded role data, temporary
export const EXCLUDED_ROLE = [
  {
    id: '73ed8e78-bb8f-4d90-8547-d5fb54d44b82',
    name: 'Super User',
  },
  {
    id: 'f3f96f4a-3408-4bed-9254-924e269d2685',
    name: 'Admin',
  },
  {
    id: '54826f1c-75ac-4ea7-8525-88dc7678c506',
    name: 'Business Owner',
  },
]

export const EXCLUDED_ROLE_BUSINESS = [
  '91e901cf-6471-4c34-86ac-fd9d6b976cc7',
  'a371f07b-d0fc-4270-86ac-ae970c72650d',
]