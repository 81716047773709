<template>
  <a-modal
    :visible="true"
    :footer="false"
    :title="null"
    class="success-invite-user"
    @cancel="() => $emit('handleModalSuccessInviteUser', false)"
  >
    <a-row>
      <a-col class="my-5 d-flex justify-content-center align-items-center">
        <img src="https://ik.imagekit.io/powerbiz/img/admin-saas/Success.png?ik-sdk-version=javascript-1.4.3&updatedAt=1634376216817">
      </a-col>

      <a-col class="mt-5 mb-3 d-flex justify-content-center align-items-center">
        <div class="pbz-font subtitle-md-medium" style="color: #1a1a1a">
          Berhasil
        </div>
      </a-col>

      <a-col class="mt-3 mb-5">
        <div class="pbz-font body-md-regular d-flex justify-content-center align-items-center" style="color: #999999">
          Anda berhasil menambahkan pengguna baru
        </div>
      </a-col>

      <a-col class="my-5">
        <div
          class="pbz-font button-md-medium d-flex justify-content-center align-items-center"
          style="
            border: 1px solid #0559CB;
            background: #0559CB;
            color: #FFFFFF;
            width: 100%;
            padding: .75rem;
            border-radius: 5px;
            cursor: pointer;
          "
          @click.prevent="() => $emit('handleModalSuccessInviteUser', false)"
        >
          Selesai
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalSuccessInviteUser',
}
</script>

<style lang="scss">
.success-invite-user {
  .ant-modal {
    width: 480px !important;
  }
}
</style>
