<template>
  <a-modal
    :visible="showModalEditUser || showModalDelUser || showModalHistoryUser || showModalChangePassword || showModalSuccesChangePass"
    :footer="null"
    :closable="!showModalSuccesChangePass"
    :width="490"
    @cancel="$emit('handleShowModal','close',false), loading = false"
  >
    <a-row>
      <a-col class="d-flex justify-content-center">
        <div
          v-if="showModalDelUser || showModalSuccesChangePass"
          style="
              width: 180px;
              height: 180px;
              background: linear-gradient(153.43deg, #F49685 0%, #EC4426 83.33%);
              border-radius: 50%;
              margin-bottom: 40px"
        >
          <TrashIcon v-if="showModalDelUser" style="width: 180px; height:auto; color: #ffffff" />
          <Checklist v-if="showModalSuccesChangePass" style="width: 180px; height:auto; color: #ffffff; position: absolute; top: 41px; right: 112px;" />
        </div>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center">
        <div class="pbz-font subtitle-md-medium">
          {{ captionTitle() }}
        </div>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center" style="margin: 10px 0px">
        <div class="pbz-font" style="width: 60%; text-align:center; color:#999999">
          {{ captionModal() }}
        </div>
        <div v-if="showModalHistoryUser" class="col-12" style="width: 370px; height: 370px; overflow: auto">
          <ul v-for="(item, index) in dataHistory" :key="index">
            <li>{{ $moment(item.created_at).format('DD MMM YYYY HH:MM') }}</li>
            <span> {{ item.event }} </span>
            <p> {{ item.description }} </p>
          </ul>
        </div>
      </a-col>
      <a-col v-if="showModalEditUser || showModalDelUser || showModalEditUser || showModalChangePassword || showModalSuccesChangePass" :span="24" class="d-flex justify-content-center" style="margin-top: 50px">
        <a-button
          class="pbz-font"
          style="
                border: 1px solid #E00000;
                background: #E00000;
                color: #FFFFFF;
                width: 100%;
                height: auto;
                padding: .75rem;
                border-radius: 5px;
                cursor: pointer;
            "
          :disabled="isLoading"
          @click.prevent="submitButton()"
        >
          <div v-if="!loading || showModalSuccesChangePass">
            {{ captionSubmit() }}
          </div>
          <div v-if="loading && !showModalSuccesChangePass">
            <a-icon type="loading" />
          </div>
        </a-button>
      </a-col>
      <a-col v-if="showModalEditUser || showModalDelUser || showModalChangePassword" :span="24" class="d-flex justify-content-center align-items-center">
        <a-button
          class="pbz-font"
          style="
              color: #E00000;
              padding: .75rem;
              cursor: pointer;
              width: 100%;
              height: auto;
              border: none;
            "
          :disabled="isLoading"
          @click="() => {$emit('handleShowModal','close',false), loading = false}"
        >
          Batal
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import Checklist from '@/components/Icons/Checklist.vue'

export default {
  name: 'ModalUser',
  components: {
    TrashIcon,
    Checklist,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showModalEditUser: {
      type: Boolean,
      default: () => false,
    },
    showModalDelUser: {
      type: Boolean,
      default: () => false,
    },
    showModalHistoryUser: {
      type: Boolean,
      default: () => false,
    },
    showModalChangePassword: {
      type: Boolean,
      default: () => false,
    },
    showModalSuccesChangePass: {
      type: Boolean,
      default: () => false,
    },
    dataHistory: {
      type: Array,
    },
  },
  data: function () {
    return {
      loading: false,
    }
  },
  watch: {
    showModalEditUser: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.loading = false
        }
      },
    },
  },
  methods: {
    captionModal() {
      if (this.showModalEditUser) {
        return 'Apakah anda yakin akan mengubah data pengguna?'
      }
      if (this.showModalChangePassword) {
        return 'Apakah anda yakin?'
      }
      if (this.showModalDelUser) {
        return 'Setelah menghapus Manajemen Pengguna pada list daftar, data ini akan langsung terhapus'
      }
      if (this.showModalSuccesChangePass) {
        return 'Kata Sandi berhasil diubah'
      }
    },
    captionTitle() {
      if (this.showModalEditUser || this.showModalChangePassword) {
        return 'Konfirmasi'
      }
      if (this.showModalDelUser) {
        return 'Anda yakin ingin menghapus?'
      }
      if (this.showModalHistoryUser) {
        return 'Riwayat Perubahan'
      }
      if (this.showModalSuccesChangePass) {
        return 'Sukses'
      }
    },
    captionSubmit() {
      if (this.showModalEditUser) {
        return 'Ya, Ubah Pengguna'
      }
      if (this.showModalDelUser) {
        return 'Konfirmasi'
      }
      if (this.showModalChangePassword) {
        return 'Ya, Ubah Kata Sandi'
      }
      if (this.showModalSuccesChangePass) {
        return 'Kembali Ke Dashboard'
      }
    },
    submitButton() {
      this.loading = true
      if (this.showModalEditUser) {
        this.$emit('submitEditUser')
      } else if (this.showModalDelUser) {
        this.$emit('submitDeleteUser')
      } else if (this.showModalChangePassword) {
        this.$emit('submitButton')
        this.loading = false
      } else if (this.showModalSuccesChangePass) {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
  },
}
</script>

<style>

</style>
