<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="168" height="84" viewBox="0 0 168 84" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M166.87 5.40257C169.754 10.2081 167.971 16.3138 162.888 19.04L44.8354 82.363C40.1061 84.8997 34.1132 83.6344 30.9745 79.4364L2.69161 41.6077C-0.681499 37.0961 0.452282 30.8534 5.22398 27.6642C9.99567 24.475 16.5983 25.547 19.9714 30.0585L42.725 60.4917L152.447 1.63778C157.529 -1.08849 163.987 0.597069 166.87 5.40257Z" fill="#FCFCFC"/>
  </svg>
</template>

<script>
export default {
  name: 'checklist.icon',
}
</script>
