<template>
  <div class="mt-5">
    <a-row class="mb-4" style="padding: 0 5rem">
      <a-col class="d-flex justify-content-start align-items-center">
        <div class="d-flex justify-content-start align-items-center" @click.prevent="toBackPage">
          <ArrowIcon
            style="color: #E00000; width: 20px; height: 20px"
            :rotate="'rotate(270)'"
          />
        </div>

        <div class="mx-3">
          <a-divider type="vertical" style="color: #CCCCCC !important" />
        </div>

        <div class="pbz-font button-sm-medium d-flex justify-content-start align-items-center" style="color: #0559CB">
          Manajemen Pengguna >
        </div>

        <div style="color: #1A1A1A" class="ml-3 pbz-font button-sm-medium d-flex justify-content-start align-items-center">
          {{ checkEditRoutePage ? 'Ubah Pengguna' : 'Tambah Pengguna' }}
        </div>
      </a-col>
    </a-row>

    <div id="form-page" style="padding: 0 7rem">
      <a-row
        style="
          border: 1px solid #CCCCCC;
          box-sizing: border-box;
          border-radius: 10px;
          background: #FFFFFF;
          padding: 40px
        "
      >
        <a-col :span="24" class="d-flex justify-content-start align-items-center pbz-font subtitle-sm-medium" style="color: #1A1A1A">
          <div> {{ checkEditRoutePage ? 'Ubah Pengguna' : 'Pengguna Baru' }} </div>
        </a-col>

        <a-col :span="24" class="my-3 d-flex justify-content-start align-items-center pbz-font body-sm-bold" style="color: #1A1A1A">
          <div> Informasi Pengguna </div>
        </a-col>

        <a-col v-if="!checkEditRoutePage" :span="24" class="my-3 d-flex justify-content-start align-items-center">
          <div
            style="
              width: 220px;
              height: 48px;
              background: #FFFFFF;
              border: 1px solid #CCCCCC;
              box-sizing: border-box;
              border-radius: 80px;
              cursor: pointer;
              font-size: 13px;
            "
            class="d-flex justify-content-around align-items-center"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;
                width: 45%;
                height: 38px;
                color: #999999;
              "
              :style="[
                activeInvitation === 'email' && {
                  background: '#0559CB',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
                  color: '#FFFFFF !important',
                  borderRadius: '60px',
                }
              ]"
              class="pbz-font button-xs-medium"
              @click="changeActiveInvitation('email')"
            >
              Email
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 45%;
                height: 38px;
                color: #999999;
              "
              :style="[
                activeInvitation === 'phone' && {
                  background: '#0559CB',
                  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
                  color: '#FFFFFF !important',
                  borderRadius: '60px',
                }
              ]"
              class="pbz-font button-xs-medium"
              @click="changeActiveInvitation('phone')"
            >
              No Handphone
            </div>
          </div>
        </a-col>

        <a-col v-if="checkEditRoutePage" :span="24" class="my-3" style="color: #1A1A1A">
          <a-input v-model="username" disabled style="border: 1.5px solid #CCCCCC !important; height: 48px !important; background-color:#f2f2f2;" placeholder="Username" />
          <label class="label-form" style="background: linear-gradient(#FFFFFF 50%, #f2f2f2 50%)"> Username</label>
        </a-col>

        <a-col v-if="checkEditRoutePage" :span="24" class="my-3" style="color: #1A1A1A">
          <a-input v-model="fullname" style="border: 1px solid #CCCCCC !important; height: 48px !important" placeholder="Nama Lengkap" :style="{ borderColor: error.emailInput && 'red' }" />
          <label v-if="checkEditRoutePage" class="label-form">Nama Lengkap</label>
          <div v-if="error.fullName" style="color: red" class="mt-1 pbz-font button-sm-medium d-flex justify-content-start align-items-center">
            {{ error.fullName }}
          </div>
        </a-col>

        <a-col v-if="activeInvitation == 'email' || checkEditRoutePage" :span="24" class="my-3" style="color: #1A1A1A">
          <a-input
            v-model="emailInput"
            :disabled="checkEditRoutePage && disableEmail"
            style="border: 1px solid #CCCCCC !important; height: 48px !important"
            placeholder="email"
            :style="{ borderColor: error.emailInput && 'red', background: checkEditRoutePage && disableEmail && '#f2f2f2'}"
            @input="handleInputEmail"
          />
          <label v-if="checkEditRoutePage" class="label-form" :style="{background: checkEditRoutePage && disableEmail && 'linear-gradient(#FFFFFF 50%, #f2f2f2 50%)'}">Email</label>
          <div v-if="error.emailInput" style="color: red" class="mt-1 pbz-font button-sm-medium d-flex justify-content-start align-items-center">
            {{ error.emailInput }}
          </div>
        </a-col>

        <a-col v-if="activeInvitation == 'phone' || checkEditRoutePage" :span="24" class="my-3" style="color: #1A1A1A">
          <a-input
            v-model="phoneInput"
            style="border: 1px solid #CCCCCC !important; height: 48px !important"
            placeholder="phone number"
            :style="{ borderColor: error.phoneInput && 'red' }"
            @input="handlePhoneInput"
          />
          <label v-if="checkEditRoutePage" class="label-form">No Handphone</label>
          <div v-if="error.phoneInput" style="color: red" class="mt-1 pbz-font button-sm-medium d-flex justify-content-start align-items-center">
            {{ error.phoneInput }}
          </div>
        </a-col>

        <a-col :span="24" class="my-3 d-flex justify-content-start align-items-center pbz-font body-sm-bold" style="color: #1A1A1A">
          <div> Role Pengguna </div>
        </a-col>

        <a-col v-if="checkEditRoutePage" :span="24" class="my-3" style="color: #1A1A1A">
          <a-select
            v-model="status"
            placeholder="Status"
            style="width: 100%;"
            class="select-role"
          >
            <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <label v-if="checkEditRoutePage" class="label-form">Status</label>
        </a-col>

        <a-col :span="24" class="my-3" style="color: #1A1A1A">
          <a-select
            v-model="idRoleSelected"
            placeholder="Pilih Role"
            style="width: 100%;"
            class="select-role"
            @change="handleSelectRole"
          >
            <a-select-option v-for="(item, index) in businessRoles" :key="index" :value="item.workspace_role_id">
              {{ item.role.name }}
            </a-select-option>
          </a-select>
          <label v-if="checkEditRoutePage" class="label-form">Role</label>

          <div v-if="error.roleInput" style="color: red" class="mt-1 pbz-font button-sm-medium d-flex justify-content-start align-items-center">
            {{ error.roleInput }}
          </div>
        </a-col>

        <a-col :span="24" class="my-3">
          <TaskForUserInvited
            ref="taskRole"
            label="Working Area"
            :type-role-task="typeRoleTask"
            :prop-task-data="propTaskDataWorkingArea"
            :prop-task-data-active="propsTaskDataActiveWorkingArea"
            :prop-error-message="error.businessInput"
            :selected-all="selectedAll"
            :flag="flag"
            :flag-warehouse-business="flagWarehouseBusiness"
            @handleParamCheckbox="handleParamCheckbox"
          />
        </a-col>
        
        <a-col :span="24" class="my-3">
          <TaskForUserInvited
            ref="taskRole"
            label="Access List"
            :type-role-task="typeRoleTask"
            :prop-task-data="propTaskDataAccessList"
            :prop-task-data-active="propsTaskDataActiveAccessList"
            :prop-error-message="error.businessInput"
            :selected-all="false"
            :flag="false"
            :flag-warehouse-business="false"
          />
        </a-col>

        <a-col :span="24" class="my-3">
          <a-row class="footer-invite-user mt-3">
            <a-col :span="12" class="d-flex justify-content-center align-items-center" @click.prevent="toBackPage">
              <div class="cancel pbz-font button-sm-medium">
                Batal
              </div>
            </a-col>
            <a-col v-if="permission.includes('WRITE')" :span="12" class="d-flex justify-content-center align-items-center" @click.prevent="submitButton(checkEditRoutePage)">
              <div class="save pbz-font button-sm-medium d-flex justify-content-center align-items-center">
                Simpan
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>

    <ModalSuccessInviteUser
      v-if="show_modal_success_invite_user"
      @handleModalSuccessInviteUser="handleModalSuccessInviteUser"
    />
    <ModalUserManagement
      :show-modal-edit-user="showModalEditUser"
      @handleShowModal="handleShowModal"
      @submitEditUser="submitEditUser"
    />
  </div>
</template>

<script>
import ModalSuccessInviteUser from '@/components/InviteUser/ModalSuccess/index'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import TaskForUserInvited from '@/components/InviteUser/SelectTaskForUserInvited'
import ModalUserManagement from '@/components/UserManagement/ModalUserManagement/index.vue'
import getQueryParam from '@/utils/getQueryParam'
import { EXCLUDED_ROLE, EXCLUDED_ROLE_BUSINESS } from '@/services/store/role.js'
import { getUserDetailById, getWorkspaceRoles, inviteUser, updateUser } from '@/api/user'
import { getBusiness } from '@/api/business'
import { getWarehouses } from '@/api/warehouse'

export default {
  name: 'InviteUser',
  components: {
    // SearchIcon,
    ModalSuccessInviteUser,
    ArrowIcon,
    TaskForUserInvited,
    ModalUserManagement,
  },
  mixins: [getQueryParam],
  data () {
    return {
      activeInvitation: 'email',
      businessRoles: [],
      businessList: [],
      show_modal_success_invite_user: false,
      emailInput: '',
      phoneInput: '',
      idRoleSelected: null,
      error: {
        emailInput: '',
        phoneInput: '',
        roleInput: '',
        businessInput: '',
        fullName: '',
      },
      typeRoleTask: 'business',
      restriction_base: '',
      restriction_level: '',
      warehouseList: [],
      propTaskData: [],
      propTaskDataWorkingArea: [],
      propTaskDataAccessList: [],
      propsTaskDataActiveWorkingArea: [],
      propsTaskDataActiveAccessList: [],
      propTaskDataActive: [],
      user: [],
      username: '',
      fullname: '',
      status: '',
      id: '',
      statusList: [
        {
          name: 'Aktif',
          value: 'ACTIVE',
        },
        {
          name: 'Tidak Aktif',
          value: 'INACTIVE',
        },
        {
          name: 'Diundang',
          value: 'INVITATION',
        },
      ],
      showModalEditUser: false,
      disableEmail: false,
      selectedAll: false,
      flag: false,
      flagWarehouseBusiness: false,
      workingList: [],
      accessList: [],
    }
  },
  computed: {
    checkEditRoutePage() {
      return this.$route.name === 'edit-user'
    },
    permission() {
      return this.$store.getters['user/can']('user-management')
    },
  },
  watch: {
    phoneInput(val) {
      this.phoneInput = val.replace(/\D/, '')
    },
    // permission(newValue) {
    //   if(!newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  created () {
    this.fetchRole()
    this.fetchBusiness()
    this.fetchWarehouse()
  },
  methods: {
    changeActiveInvitation(param) {
      if (param === this.activeInvitation) {
        return
      }

      this.activeInvitation = param
    },
    async fetchRole () {
      await getWorkspaceRoles()
      .then(({ data: { data: response } }) => {
        let finalExcludedRole = EXCLUDED_ROLE?.map((obj) => obj.id)
        if(this.$store.state.user.role_id === '91e901cf-6471-4c34-86ac-fd9d6b976cc7' || this.$store.state.user.role_name?.toLowerCase() === 'business admin') {
          finalExcludedRole = EXCLUDED_ROLE_BUSINESS.concat(finalExcludedRole)
        }
        this.businessRoles = response.filter((obj) => !finalExcludedRole.includes(obj.role.id))
      })
    },
    async fetchWarehouse () {
      await getWarehouses({
        page: 1,
        limit: 1000,
        business_id: this.$route.query['business_id'],
      })
      .then(({ data }) => {
        this.warehouseList = data.data.data.map(item => ({
          label: item.name,
          value: item.warehouse_id,
          checked: false,
          businessId: item.business_id,
        }))
      })
    },
    async fetchBusiness () {
      await getBusiness()
      .then(({ data: { data: response } }) => {
        this.businessList = response.map(item => ({
          label: item.business_title,
          value: item.business_id,
          checked: false,
        }))

        if (this.checkEditRoutePage) {
          this.workingList = response.map(item => ({
            label: item.business_title,
            value: item.business_id,
            checked: false,
          }))
          this.accessList = response.map(item => ({
            label: item.business_title,
            value: item.business_id,
            checked: false,
          }))
          this.propTaskDataWorkingArea = this.workingList
          this.propTaskDataAccessList = this.accessList
          this.propsTaskDataActiveWorkingArea = this.workingList
          this.propsTaskDataActiveAccessList = this.accessList
        } else {
          // this.propTaskData = this.businessList
          // this.propTaskDataActive = this.businessList
          this.propTaskDataWorkingArea = this.businessList
          this.propTaskDataAccessList = this.businessList
          this.propsTaskDataActiveWorkingArea = [...this.businessList]
          this.propsTaskDataActiveAccessList = [...this.businessList]
        }
        this.fetchDetailUser()
      })
    },
    async fetchDetailUser() { // this method for edit page
      if (this.checkEditRoutePage) {
        await getUserDetailById()
        .then(({ data: { data: response } }) => {
          if(response.email !== '') {
            this.disableEmail = true
          }
          this.user = response
          this.id = response.id
          this.username = response.username
          this.fullname = response.full_name
          this.emailInput = response.email
          this.phoneInput = response.phone_number
          this.status = response.status
          this.idRoleSelected = response.user_workspaces.length === 1 ? response.user_workspaces[0].workspace_role.workspace_role_id : null // null to be updated if workspace role > 1

          if (response.user_workspaces[0].workspace_role.restriction_base === "Business" && response.user_workspaces[0].workspace_role.restriction_level === "Workspace") {
            this.flag = false
            this.selectedAll = true
            this.restriction_base = response.user_workspaces[0].workspace_role.restriction_base
            this.restriction_level = response.user_workspaces[0].workspace_role.restriction_level
            this.propsTaskDataActiveAccessList.filter(elem => response.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          } else if (response.user_workspaces[0].workspace_role.restriction_base === "Business" && response.user_workspaces[0].workspace_role.restriction_level === "Business") {
            this.flag = true
            this.selectedAll = false
            this.restriction_base = response.user_workspaces[0].workspace_role.restriction_base
            this.restriction_level = response.user_workspaces[0].workspace_role.restriction_level
            this.propsTaskDataActiveWorkingArea.filter(elem => response.user_workspaces[0].business_id.find(item => elem.value === item)).map(item => { item.checked = true })
            this.propsTaskDataActiveAccessList.filter(elem => response.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          } else if (response.user_workspaces[0].workspace_role.restriction_base === "Warehouse" && response.user_workspaces[0].workspace_role.restriction_level === "Workspace") {
            this.flag = false
            this.selectedAll = true
            this.restriction_base = response.user_workspaces[0].workspace_role.restriction_base
            this.restriction_level = response.user_workspaces[0].workspace_role.restriction_level
            this.propTaskDataAccessList = this.warehouseList
            this.propsTaskDataActiveAccessList = this.warehouseList
            this.propsTaskDataActiveAccessList.filter(elem => response.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          } else if (response.user_workspaces[0].workspace_role.restriction_base === "Warehouse" && response.user_workspaces[0].workspace_role.restriction_level === "Business") {
            this.flagWarehouseBusiness = true
            this.selectedAll = false
            this.restriction_base = response.user_workspaces[0].workspace_role.restriction_base
            this.restriction_level = response.user_workspaces[0].workspace_role.restriction_level
            this.propTaskDataWorkingArea = this.businessList
            this.propTaskDataAccessList = this.warehouseList
            this.propsTaskDataActiveWorkingArea = this.businessList
            this.propsTaskDataActiveAccessList = this.warehouseList
            this.propsTaskDataActiveWorkingArea.filter(elem => response.user_workspaces[0].business_id.find(item => elem.value === item)).map(item => { item.checked = true })
            this.propsTaskDataActiveAccessList.filter(elem => response.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          }

          // const isWarehouseOperator = this.businessRoles.find(item => item.workspace_role_id === this.idRoleSelected && item.role.name.toLowerCase() === 'warehouse operator')

          // if (isWarehouseOperator) {
          //   this.typeRoleTask = 'warehouse'
          //   this.propTaskData = this.warehouseList
          //   this.propTaskDataActive = this.warehouseList
          //   this.propTaskDataActive.filter(elem => data.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          // } else {
          //   this.typeRoleTask = 'business'
          //   this.propTaskData = this.businessList
          //   if (data.user_workspaces[0].restriction_list[0] === '*') {
          //     this.propTaskDataActive.map(item => { item.checked = true })
          //   } else {
          //     this.propTaskDataActive.filter(elem => data.user_workspaces[0].restriction_list.find(item => elem.value === item)).map(item => { item.checked = true })
          //   }
          // }
        })
        .catch((err) => console.error(err))
      }
    },
    handleModalSuccessInviteUser () {
      this.$router.push({ path: '/user-management' })
    },
    handleInputEmail (e) {
      if (e.target.value.length) {
        this.error.emailInput = ''
      }

      this.emailInput = e.target.value
    },
    handlePhoneInput (e) {
      if (e.target.value.length) {
        this.error.phoneInput = ''
      }
      this.phoneInput = e.target.value
    },
    handleSelectRole (value) {
      this.idRoleSelected = value
      const filter = this.businessRoles.filter(e => {return e.workspace_role_id === value})

      if (value) {
        this.error.roleInput = ''
      }

      if (filter[0].restriction_base === "Business" && filter[0].restriction_level === "Workspace") {
        this.flag = false
        this.selectedAll = true
        this.restriction_base = filter[0].restriction_base
        this.restriction_level = filter[0].restriction_level
        this.propTaskDataWorkingArea = []
        this.propTaskDataAccessList = this.businessList
        this.propsTaskDataActiveWorkingArea = []
        this.propsTaskDataActiveAccessList = this.businessList
      } else if (filter[0].restriction_base === "Business" && filter[0].restriction_level === "Business") {
        this.flag = true
        this.selectedAll = false
        this.restriction_base = filter[0].restriction_base
        this.restriction_level = filter[0].restriction_level
        this.propTaskDataWorkingArea = this.businessList
        this.propTaskDataAccessList = this.businessList
        this.propsTaskDataActiveWorkingArea = this.businessList
        this.propsTaskDataActiveAccessList = this.businessList
      } else if (filter[0].restriction_base === "Warehouse" && filter[0].restriction_level === "Workspace") {
        this.flag = false
        this.selectedAll = true
        this.restriction_base = filter[0].restriction_base
        this.restriction_level = filter[0].restriction_level
        this.propTaskDataAccessList = this.warehouseList
        this.propTaskDataWorkingArea = []
        this.propsTaskDataActiveAccessList = this.warehouseList
        this.propsTaskDataActiveWorkingArea = []
      } else if (filter[0].restriction_base === "Warehouse" && filter[0].restriction_level === "Business") {
        this.flagWarehouseBusiness = true
        this.selectedAll = false
        this.restriction_base = filter[0].restriction_base
        this.restriction_level = filter[0].restriction_level
        this.propTaskDataWorkingArea = this.businessList
        this.propTaskDataAccessList = this.warehouseList
        this.propsTaskDataActiveWorkingArea = this.businessList
        this.propsTaskDataActiveAccessList = this.warehouseList
      }
      // const isWarehouseOperator = this.businessRoles.find(item => item.workspace_role_id === value && item.role.name.toLowerCase() === 'warehouse operator')

      // if (isWarehouseOperator) {
      //   this.typeRoleTask = 'warehouse'
      //   this.propTaskData = this.warehouseList
      //   this.propTaskDataActive = this.warehouseList
      // } else {
      //   this.typeRoleTask = 'business'
      //   this.propTaskData = this.businessList
      //   this.propTaskDataActive = this.businessList
      // }
    },
    handleParamCheckbox (value, param) {
      const filterValue = value.filter(e => {return e.checked})
      if (param === this.flag) {
        this.propTaskDataAccessList = filterValue
        this.propsTaskDataActiveAccessList = filterValue
      }
      if (filterValue.length === 0 && param !== this.flagWarehouseBusiness) {
        this.propTaskDataAccessList = value
        this.propsTaskDataActiveAccessList = value
      }
      if (param === this.flagWarehouseBusiness) {
        let warehouseBusiness = []
        for (let i = 0; i < filterValue.length; i++) {
          const warehouseFilter = this.warehouseList.filter(e => {return e.businessId === filterValue[i].value})
          warehouseBusiness.push(...warehouseFilter)
        }
        const warehouseChecked = warehouseBusiness.map((v) => {
          return {
            ...v,
            checked: true,
          }
      })
        this.propTaskDataWorkingArea = value
        this.propsTaskDataActiveWorkingArea = value
        this.propTaskDataAccessList = warehouseChecked
        this.propsTaskDataActiveAccessList = warehouseChecked
      }
      if (filterValue.length === 0 && param === this.flagWarehouseBusiness) {
        this.propTaskDataAccessList = this.warehouseList
        this.propsTaskDataActiveAccessList = this.warehouseList
      }
    },
    handleShowModal(type, param) {
      switch (type) {
        case 'edit' :
          this.showModalEditUser = param
          break
        case 'close' :
          this.showModalEditUser = param
          break
      }
    },
    async submitInviteUser () {
      if (!this.emailInput && this.activeInvitation === 'email') {
        this.error.emailInput = 'Email tidak boleh kosong'
      }

      if (!this.phoneInput && this.activeInvitation === 'phone') {
        this.error.phoneInput = 'No. Telephone tidak boleh kosong'
      }

      if (!this.idRoleSelected) {
        this.error.roleInput = 'Role tidak boleh kosong'
      }

      if (this.$refs.taskRole.totalTaskSelected < 1) {
        this.error.businessInput = this.typeRoleTask === 'business' ? 'Business tidak boleh kosong' : 'Warehouse tidak boleh kosong'
      }

      if (
        this.error.emailInput ||
        this.error.phoneInput ||
        this.error.roleInput ||
        this.error.businessInput
      ) {
        const element = document.getElementById('form-page')
        return element.scrollIntoView({
          behavior: 'smooth',
        })
      }

      let businessId = []
      let restrictionList = []

      if ((this.restriction_base === "Business" && this.restriction_level === "Workspace") || (this.restriction_base === "Warehouse" && this.restriction_level === "Workspace")) {
        restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      } else if (this.restriction_base === "Business" && this.restriction_level === "Business") {
        businessId = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.propTaskDataAccessList.filter(item => item.checked).map(v => v.value)
        restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      } else if (this.restriction_base === "Warehouse" && this.restriction_level === "Business") {
        businessId = this.propTaskDataWorkingArea.filter(item => item.checked).map(v => v.value)
        restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      }

      // if (this.typeRoleTask === 'business' && this.$refs.taskRole.checkedAll) {
      //   businessId = this.$refs.taskRole.taskData.map(item => item.value)
      // } else if (this.typeRoleTask === 'business' && !this.$refs.taskRole.checkedAll) {
      //   businessId = this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      // }

      // if (this.typeRoleTask === 'warehouse' && this.$refs.taskRole.checkedAll) {
      //   warehouseId = this.$refs.taskRole.taskData.map(item => item.value)
      // } else if (this.typeRoleTask === 'warehouse' && !this.$refs.taskRole.checkedAll) {
      //   warehouseId = this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      // }

      const payload = {
        name: '', // to be updated
        email: this.emailInput,
        phone_number: this.phoneInput,
        workspace_role_id: this.idRoleSelected,
        business_id: businessId,
        restriction_list: restrictionList,
        admin: this.$store.state.user.full_name, // yang login (yang bisa invite)
        // platformId: this.$store.state.user.platformActive ? this.$store.state.user.platformActive.id : '', // yang login (yang bisa invite)
        // platformName: this.$store.state.user.platformActive ? this.$store.state.user.platformActive.name : '', // yang login (yang bisa invite)
      }

      await inviteUser(payload)
      .then(() => this.show_modal_success_invite_user = true)
      .catch((err) => {
        this.$notification.error({
          message: err.response?.data.message,
        })
      })
    },
    async submitEditUser() {
      let businessId = []
      let restrictionList = []

       if ((this.restriction_base === "Business" && this.restriction_level === "Workspace") || (this.restriction_base === "Warehouse" && this.restriction_level === "Workspace")) {
          restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
        } else if (this.restriction_base === "Business" && this.restriction_level === "Business") {
          businessId = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.propTaskDataAccessList.filter(item => item.checked).map(v => v.value)
          restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
        } else if (this.restriction_base === "Warehouse" && this.restriction_level === "Business") {
          businessId = this.propTaskDataWorkingArea.filter(item => item.checked).map(v => v.value)
          restrictionList = this.$refs.taskRole.checkedAll ? this.$refs.taskRole.taskData.map(item => item.value) : this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
        }
      // let role = {}
      // role = this.businessRoles.find(item => item.workspace_role_id === this.idRoleSelected)
      // delete role.attributes
      // delete role.clientRole
      // delete role.composites
      // delete role.composite
      // delete role.containerId
      // delete role.scopeParamRequired

      // if (this.typeRoleTask === 'business' && this.$refs.taskRole.checkedAll) {
      //   businessId = this.$refs.taskRole.taskData.map(item => item.value)
      // } else if (this.typeRoleTask === 'business' && !this.$refs.taskRole.checkedAll) {
      //   businessId = this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      // }

      // if (this.typeRoleTask === 'warehouse' && this.$refs.taskRole.checkedAll) {
      //   warehouseId = this.$refs.taskRole.taskData.map(item => item.value)
      // } else if (this.typeRoleTask === 'warehouse' && !this.$refs.taskRole.checkedAll) {
      //   warehouseId = this.$refs.taskRole.taskDataActive.filter(item => item.checked).map(v => v.value)
      // }
  
      const payload = {
        id: this.id,
        username: this.username,
        full_name: this.fullname,
        status: this.status,
        email: this.emailInput,
        phone_number: this.phoneInput,
        workspace_role_id: this.idRoleSelected,
        business_id : businessId,
        restriction_list : restrictionList,
    }
      

      // const payload = {
      //   id: this.id,
      //   username: this.username,
      //   full_name: this.fullname,
      //   email: this.emailInput,
      //   phone_number: this.phoneInput,
      //   workspace_role_id: this.idRoleSelected,
      //   status: this.status,
      //   business_id: businessId,
      //   warehouse_id: warehouseId,
      // }
      await updateUser({
        type: '',
        data: payload,
      })
      .then(() => {
        this.$notification.success({
          message: 'Data Berhasil di update',
        })
        setTimeout(() => {
          this.toBackPage()
        }, 1000)
      })
      .catch((err) => {
        this.$notification.error({
          message: err,
        })
      })
    },
    submitButton(param) {
      if (param) {
        if (!this.fullname) {
          this.error.fullName = 'Nama lengkap tidak boleh kosong'
        }
        if (!this.emailInput) {
          this.error.emailInput = 'Email tidak boleh kosong'
        }

        if (!this.phoneInput) {
          this.error.phoneInput = 'No. Telephone tidak boleh kosong'
        }

        if (!this.idRoleSelected) {
          this.error.roleInput = 'Role tidak boleh kosong'
        }

        if (this.$refs.taskRole.totalTaskSelected === 0) {
          this.error.businessInput = this.typeRoleTask === 'business' ? 'Business tidak boleh kosong' : 'Warehouse tidak boleh kosong'
        }

        if (
          this.error.fullName ||
          this.error.emailInput ||
          this.error.phoneInput ||
          this.error.roleInput ||
          (this.error.businessInput && this.$refs.taskRole.totalTaskSelected === 0)
        ) {
          const element = document.getElementById('form-page')
          return element.scrollIntoView({
            behavior: 'smooth',
          })
        } else {
          this.handleShowModal('edit', true)
        }
      } else {
        this.submitInviteUser()
      }
    },
    toBackPage() {
      this.$router.push({
        name: 'user-management',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style lang="scss">
.ant-select-selection {
  height: 48px !important;

  &__rendered {
    margin-top: 7px !important;
  }
}

.field {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: left;
  padding-left: 2.5rem !important;
  padding: 10px 15px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 5px;
  color: #999999;
}

.search-box > .i {
  position: absolute;
  margin: 1rem 0 0 0.5rem;
}

.field::placeholder {
  color: #999999 !important;
}

.footer-invite-user {
  .cancel {
    color: #E00000;
    padding: .75rem;
    cursor: pointer;
  }

  .save {
    border: 1px solid #E00000;
    background: #E00000;
    color: #FFFFFF;
    width: 100%;
    padding: .75rem;
    border-radius: 5px;
    cursor: pointer;
  }
}
.label-form {
  position: absolute;
  left: 15px;
  top: -11px;
  background: #FFFFFF;
  color: #B3B3B3;
}
</style>
