<template>
  <a-row>
    <a-col :span="24" class="my-3 d-flex justify-content-start align-items-center pbz-font body-sm-bold" style="color: #1A1A1A">
      <div> {{ label }} </div>
    </a-col>

    <a-col :span="24" class="my-3 d-flex justify-content-between align-items-center">
      <div v-if="selectedAll === false" class="pbz-font">
        <a-checkbox :checked="checkedAll" :indeterminate="indeterminateSelectTask" style="color: #999999" @change="handleChangeSelectAllTask">
          {{ selectAllCaption }}
        </a-checkbox>
      </div>
      <div v-else class="pbz-font">
        {{ selectAllCaption }}
      </div>

      <div v-if="selectedAll === false" class="pbz-font" style="color: #0559CB; cursor: pointer" @click="deleteSelectAllCheckbox">
        Bersihkan Pilihan
      </div>
    </a-col>

    <a-col v-if="totalTaskSelected > 0 && !errorMessage" :span="24" class="my-3 d-flex justify-content-start align-items-center">
      <div v-if="selectedAll === false" style="color: #999999" class="pbz-font buttom-sm-medium">
        {{ totalTaskSelected }} item terpilih
      </div>
    </a-col>

    <a-col v-if="errorMessage" :span="24" class="my-3 d-flex justify-content-start align-items-center">
      <div style="color: red" class="pbz-font buttom-sm-medium">
        {{ errorMessage }}
      </div>
    </a-col>

    <a-col
      v-if="selectedAll === false"
      :span="24"
      class="my-3"
      style="border: 1px solid #CCCCCC; padding: 40px 20px; border-radius: 8px"
      :style="{ borderColor: errorMessage ? 'red' : '#CCCCCC' }"
    >
      <div class="search-box">
        <SearchIcon class="i" style="color: #999999" />
        <input 
          v-model="searchBarTaskRole"
          class="field" 
          type="text" 
          :placeholder="$t('fulfillment.search')"
          @input="handleSearchTaskRole"
        >
      </div>

      <div v-if="selectedAll === false" class="my-5 checkbox-business-list" style="overflow: scroll; height: 390px;">
        <a-row>
          <a-col v-for="(item, index) in taskDataActive" :key="index" :span="8" class="mb-4 d-flex justify-content-start">
            <a-checkbox
              class="pbz-font"
              :checked="item.checked"
              style="
                color: #999999 !important;
              "
              @change="(e) => handleChangeCheckbox(e, item)"
            >
              {{ item.label }}
            </a-checkbox>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import SearchIcon from '@/components/Icons/Search.vue'

export default {
  components: {
    SearchIcon,
  },
  props: {
    typeRoleTask: {
      validator: function (value) {
        return ['business', 'warehouse'].includes(value)
      },
      default: () => 'business',
      type: String,
    },
    propTaskData: {
      default: () => [],
      type: Array,
    },
    propTaskDataActive: {
      default: () => [],
      type: Array,
    },
    propErrorMessage: {
      default: () => '',
      value: String,
    },
    label: {
      default: () => '',
      value: String,
    },
    selectedAll: {
      default: () => false,
      value: Boolean,
    },
    flag: {
      default: () => false,
      value: Boolean,
    },
    flagWarehouseBusiness: {
      default: () => false,
      value: Boolean,
    },
  },
  data () {
    return {
      checkedAll: false,
      indeterminateSelectTask: false,
      taskData: [],
      taskDataActive: [],
      totalTaskSelected: 0,
      errorMessage: null,
      searchBarTaskRole: '',
    }
  },
  computed: {
    selectCaption () {
      return this.typeRoleTask === 'business' ? 'Pilih Bisnis' : 'Pilih Warehouse'
    },
    selectAllCaption () {
      return `Pilih Semua ${this.label}`
      // return this.typeRoleTask === 'business' ? 'Pilih Semua Bisnis' : 'Pilih Semua Warehouse'
    },
  },
  watch: {
    propTaskData: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.taskData = value
      },
    },
    propTaskDataActive: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.taskDataActive = value
      },
    },
    propErrorMessage: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.errorMessage = value
        }
      },
    },
    taskDataActive: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (Array.isArray(value) && value.length) {
          let countChecked = 0

          value.forEach(item => {
            if (item.checked) {
              countChecked++
            }
          })

          if (countChecked === value.length) {
            this.checkedAll = true
            this.indeterminateSelectTask = false
          } else if (countChecked > 0 && (countChecked !== value.length)) {
            this.indeterminateSelectTask = true
            this.checkedAll = false
          } else {
            this.indeterminateSelectTask = false
            this.checkedAll = false
          }

          if (countChecked > 0) {
            this.errorMessage = ''
          }

          this.totalTaskSelected = countChecked
        }
      },
    },
  },
  methods: {
    handleChangeSelectAllTask (e) {
      this.taskDataActive = this.taskDataActive.map((v) => {
        return {
          ...v,
          // eslint-disable-next-line
          checked: e.target.checked ? true : false,
        }
      })

      this.taskData = this.taskDataActive
      if (this.flag) {
        this.$emit('handleParamCheckbox', this.taskDataActive, this.flag)
      }
      if (this.flagWarehouseBusiness) {
        this.$emit('handleParamCheckbox', this.taskDataActive, this.flagWarehouseBusiness)
      }
    },
    deleteSelectAllCheckbox () {
      this.taskDataActive = this.taskDataActive.map((v) => {
        return {
          ...v,
          checked: false,
        }
      })

      this.taskData = this.taskDataActive
    },
    handleSearchTaskRole (e) {
      const q = e.target.value

      this.taskDataActive = this.taskData.filter((item) => {
        if (item.label !== null) {
          return item.label.toLowerCase().indexOf(q.toLowerCase()) >= 0
        }
      })

      if (!q.length && this.taskData.length) {
        this.taskDataActive = this.taskData
      }
    },
    handleChangeCheckbox(e, item) {
      this.taskDataActive = this.taskData.map((v) => {
        if (v.value === item.value) {
          return {
            ...v,
            checked: e.target.checked,
          }
        } else {
          return v
        }
      })

      this.taskData = this.taskDataActive
      if (this.flag) {
        this.$emit('handleParamCheckbox', this.taskDataActive, this.flag)
      }
      if (this.flagWarehouseBusiness) {
        this.$emit('handleParamCheckbox', this.taskDataActive, this.flagWarehouseBusiness)
      }

      this.errorMessage = ''
    },
  },
}
</script>

<style>

</style>
